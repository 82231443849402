import React from 'react';
import { Datagrid, TextField,  DateField, ExportButton, BooleanField,FilterButton, List,required, SelectInput,SimpleForm, TextInput,TopToolbar, useRecordContext,useNotify,useRefresh,useListContext } from 'react-admin';

import { useState } from "react";

import Button from '@mui/material/Button';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DescriptionIcon from '@mui/icons-material/Description';
import ArticleIcon from '@mui/icons-material/Article';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


import QuickreplyIcon from '@mui/icons-material/Quickreply';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import AdjustIcon from '@mui/icons-material/Adjust';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Box, Container,  Typography } from '@mui/material';
import { Route } from 'react-router';

import { Link } from "react-router-dom";
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { FileUpload } from "./fileupload";
import { Drawer } from '@material-ui/core'

import { GetDocumentUrl, GetDocumentObj } from "../dataproviders/documents";
import { GetBatchAnalysisResult} from "../dataproviders/analysis";
import {isMobile} from 'react-device-detect';

import { AnalysisToolbar } from '../components/analysistoolbar';
import { DocumentSetCombo, DocumentsCombo } from '../components/createdocumentset';
import { TemplatesCombo } from '../components/templatescombo';
import { BatchAnalysisToolbar } from '../components/batchanalysistoolbar';
import { DataPointsCombo } from "../components/frameworkselector";
import { AnalysisOutput } from '../components/analysisoutput';


import '../css/documents.css'



const DocLink = (props,ref) => {
    const record = useRecordContext();

    return(
        <Link {...props} to={`/documents/view/${record.id}`} target={`_view_${record.id}`}>{`${record.filename}`}</Link>
    )
}


const ResultButton = (props,ref) => {
    const record = useRecordContext();
    const notify = useNotify();

    const downloadresults = ()=>{
            //if mobile, download the file directory
            GetBatchAnalysisResult({batchid:record.id}).then((doc)=>{
                const buff = Buffer.from(doc.filedata.data, "base64");
                const xlsx = new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet' });
                const targeturl = URL.createObjectURL(xlsx);
                const a = document.createElement('a');
                a.download = doc.filedata.filename;
                a.href = targeturl;
                a.addEventListener('click', (e) => {
                    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
                });
                a.click();
                notify(`${a.download} is being saved to your Downloads folder.`, { type: 'success' });
            });
       

           
    }

    return(
        <Button {...props} to={`/documents/view/${record.id}`} target={`_view_${record.id}`} onClick={downloadresults}>Download Results</Button>
    )
}

const DurationTextField = (props, ref) => {
    const record = useRecordContext();
    
    let dt1 = new Date(record.created_date)
    let dt2 = new Date(record.completed_date||Date.now());
    let totalminutes = (dt2-dt1)/1000/60;
    let hours = Math.floor(totalminutes/60);
    let minutes = Math.round((totalminutes-(hours*60)));
    let hourslabel = (hours==1)?' hour':' hours';
    let duration = `${(hours>0)?hours + hourslabel:''} ${parseInt(minutes)} minute${(parseInt(minutes)==1)?'':'s'}`
    return (
       <span>{duration}</span>
    )
}

const Filters = [
    <TextInput label="Assessment Name" source="analysisname" defaultValue="" />,
    <TextInput label="Template" source="templatename" defaultValue="" />,
    <TextInput label="Document Set" source="docsetname" defaultValue="" />,
    <TextInput label="Status" source="status" defaultValue="" />,
]


export const BatchAnalysisList = (props) => {
    
    const refresh=useRefresh();
    let response = []
    
    const [formdata, setFormData] = useState([]);
    const [rcontext, setRContext] = useState([]);
    const [rchoices, setRChoices] = useState([]);
    const [analysisout, setAnalysisOut] = useState([]);
    const [plug, doRefresh] = useState([]);
    const [targetsource, setTargetSource] = useState(1);
    const [seldocset,SetDocsetId]=useState('');
    const [seltemplate,SetTemplateId]=useState('');
    const [seltemplatename,SetTemplateName]=useState('');
    const [dataPointSource, setDataPointSource]=useState('FREEFORM');
    const [analysisDataPoint, setAnalysisDataPoint]=useState('');
    const { data, isLoading } = useListContext();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

      const toggleDrawer =
      (anchor, open) =>
      (event) => {
        if (
          event.type === 'keydown' &&
          ((event).key === 'Tab' ||
            (event).key === 'Shift')
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

     
      
      
      const onsubmit_callback=(form) => {
          let rsp = { "question": form.question, "context": form.context, "docsetid": form.docsetid, "docid":form.docid,};
          analysisout.push(rsp);
          setAnalysisOut(analysisout);
          doRefresh(Math.random());
      }
         
      const onsuccess_callback= (rspdata,form) => { 
  
          let rsp = { "question": form.question, "context": form.context, "docsetid": form.docsetid, "docid":form.docid, "response":rspdata};
          analysisout[analysisout.length-1] = rsp;
          setAnalysisOut(analysisout);
          doRefresh(Math.random());
      }
  
      const onerror_callback=(err,form) =>{
          console.log('error encountered running analysis');
          console.log(err);
          let rsp = { "question": form.question, "context": form.context, "docsetid": form.docsetid, "docid":form.docid, "response": {"item":[]}, "error":{"message":JSON.stringify(err)}};
          analysisout[analysisout.length-1] = rsp;
          setAnalysisOut(analysisout);
          doRefresh(Math.random());
  
      }
  
      const analysisDataPointChange=(event)=>{
          setAnalysisDataPoint(event);
      }
  
      const analysisSourceChange=(event)=>{
          if(event.target.value){
              setDataPointSource(event.target.value);
          }
      }
  
      const target_onChange=()=>{
          if(targetsource==1){
              setTargetSource(2);
          }else{
              setTargetSource(1);
          }
      }

      const callback_handler=(action,params)=>{
    
        /*switch(action.toLowerCase()){
            case 'onuploadstart':
                setIsUploading(true);
            break;
            case 'onuploadcomplete':
                setIsUploading(false);
                refresh();
            break;
        }*/
    }

    const isvalidform=(event)=>{
        
        let valid=false;
        valid = (seldocset.length>0)&&(seltemplate.length>0)&&(seltemplatename.length>=3);

        return valid.toString();
    }
  
      const changedocset=(event)=>{
        SetDocsetId(event);
      }
      const changetemplate=(event)=>{
        SetTemplateId(event);
      }
        
      const changetemplatename=(event)=>{
            SetTemplateName(event.target.value);
      }

      const ListActions = () => (
            <TopToolbar>
                <FilterButton/>
                <Button
                    onClick={toggleDrawer('right', true)}
                    label="upload"
                >
                    <AddCircleOutlineIcon/>
                </Button>
                <ExportButton/>
            </TopToolbar>
        );

        const Empty = () => (
            <Container>
                 <Container>
                    <Container  className="ResponseContainer" disableGutters>
                        <div className="question_noout_panel">
                            <div className="question_noout_container">
                            
                                <div className="question_noout_child">
                                    <ul className="infodisplay">
                                        <li><PsychologyAltIcon color="info" sx={{fontSize:'72px'}} /></li>
                                        <li className="heading">1.  Select a Template</li>
                                        <li>Select an Assessment Template containing the data points you would like to evaluate.</li>
                                    </ul>
                                </div>
                                <div className="question_noout_child">
                                    <ul className="infodisplay">
                                        <li><AdjustIcon color="info" sx={{fontSize:'72px'}}/></li>
                                        <li className="heading">2.  Set a Target</li>
                                        <li>Select a Document Set or Document as your assessment target.</li>
                                        
                                    </ul>
                                </div>
                                <div className="question_noout_child">
                                    <ul className="infodisplay">
                                        <li><QuickreplyIcon color="info" sx={{fontSize:'72px'}} /></li>
                                        <li className="heading">3.  Launch Assessment</li>
                                        <li>
                                            The created assessment will be queued for execution and results will be availalbe for download when they are ready.
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Container>
                <Container className="getstarted">
                    <div>Create your first Assessment now!</div>
                    <div>
                        <Tooltip title="Upload">
                            <Button
                                onClick={toggleDrawer('right', true)}
                                label="Launch Assessment"
                                color="info"
                                sx={{fontSize:'72px'}}>
                                <AddCircleOutlineIcon fontSize="inherit" />
                            </Button>
                        </Tooltip>
                    </div>
                </Container>
            </Container>
        );
    
    

    if (isLoading) return null;
    return (
        <React.Fragment>
            <List {...props}  filters={Filters} 
                    actions={<ListActions/>}
                    empty={<Empty />} >
                <Datagrid>
                    <TextField source="analysisname" label="Assessment" />
                    <TextField source="templatename" label="Template" />
                    <TextField source="docsetname" label="Document Set" />
                    <TextField source="status" label="Assessment Status"  />
                    <TextField source="completecount" label="Completed Data Points"  />
                    <TextField source="totalanalysispoints" label="Total Data Points"  />
                    <DateField source="created_date" label="Launched" />
                    <DateField source="completed_date" label="Completed" />
                    <DurationTextField label="Duration" />
                    <ResultButton />
                    
                </Datagrid>
            </List>
           
            <Drawer
                anchor="right"
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
            >
                <div style={{minWidth:'600px'}}>
                <SimpleForm toolbar={<BatchAnalysisToolbar callback_events={callback_handler} formvalidated={isvalidform()} />} > 
                    <TextInput source="analysisname" fullWidth label="Assessment Name" id="txtanalysisname" onChange={changetemplatename} validate={required()} />
                    <div className="flex-container">
                        <div className="flex-child">
                            <TemplatesCombo TextFieldProps={{placeholder: 'Select an Assessment Template'}} onChange={changetemplate} id="ddltemplate" validate={required()} />
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="flex-child">
                                { /*(targetsource==1)?<DocumentSetCombo TextFieldProps={{placeholder: 'Select a document set'}} validate={[required()]} />:<DocumentsCombo TextFieldProps={{placeholder: 'Select a document'}} validate={[required()]} /> */}
                                    <DocumentSetCombo TextFieldProps={{placeholder: 'Must have Document in COMPLETE Status.'}} onChange={changedocset} id="ddldocset" validate={required()} status="COMPLETE" />
                                </div>
                                <div className="flex-child-second">
                                    <DocumentsCombo TextFieldProps={{placeholder: 'Only Documents in COMPLETE Status are available for analysis.'}} selecteddocset={seldocset} id="ddldoc" status="COMPLETE" />
                                </div>
                    </div>
                </SimpleForm>
                </div>
                
            </Drawer>
           
        </React.Fragment>
    )
}






    
    