import React from 'react';
import { Datagrid, TextField,  DateField, BooleanField, TextInput, useRecordContext,useListContext } from 'react-admin';
import { 
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    List,
    
    
} from 'react-admin';

import Button from '@mui/material/Button';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import {TextField as TextBox} from '@material-ui/core'
import DescriptionIcon from '@mui/icons-material/Description';
import ArticleIcon from '@mui/icons-material/Article';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';


import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Box, Container,  Typography } from '@mui/material';
import { Route } from 'react-router';

import { Link } from "react-router-dom";
import UploadFileIcon from '@mui/icons-material/UploadFile';


import { CreateDocumentSet } from './createdocset';
import { Drawer } from '@material-ui/core'

import { GetDocumentUrl, GetDocumentObj } from "../dataproviders/documents";
import {isMobile} from 'react-device-detect';

import '../css/documentsets.css'



const Filters = [
    <TextInput label="Document Set" source="docsetname" defaultValue="" />
]


export const DocumentSetList = (props) => {
    
    const { data, isLoading } = useListContext();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

      const toggleDrawer =
      (anchor, open) =>
      (event) => {
        if (
          event.type === 'keydown' &&
          ((event).key === 'Tab' ||
            (event).key === 'Shift')
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

      const ListActions = () => (
            <TopToolbar>
                <FilterButton/>
                <Button
                    onClick={toggleDrawer('right', true)}
                    label="upload"
                >
                    <UploadFileIcon/>
                </Button>
                <ExportButton/>
            </TopToolbar>
        );

        const ContentFilters = (props,ref)=>{
            const record = useRecordContext();
            return (record.contentfilters)?<ul>{record.contentfilters.map(m => <li>Replace <i>{m.filter}</i> with <i>{m.replace}</i></li>)}</ul>:null;
        }

        const DocumentCount = (props,ref)=>{
            const record = useRecordContext();
            return (<div>{record.docids.length}</div>)
        }


        const Empty = () => (
            <Container>
                 <div className="question_noout_panel">
                    <div className="question_noout_container">
                    
                        <div className="question_noout_child">
                            <ul className="infodisplay">
                            
                                <li><DocumentScannerIcon color="info" sx={{fontSize:'72px'}} /></li>
                                <li className="heading">Document Sets</li>
                                <li>Documents are organized into Document Sets.  Each document may be associated with one Document Set.  Document Sets are used
                                    to filter your Documents view or target Assesment and Analysis across many documents at once.
                                </li>
                                
                            </ul>
                        </div>
                        <div className="question_noout_child">
                            <ul className="infodisplay">
                                <li><PlagiarismIcon color="info" sx={{fontSize:'72px'}} /></li>
                                <li className="heading">Filters</li>
                                <li>
                                    Filters provide a method of redacting sensitive information with key word matching and replacement.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Container className="getstarted">
                    <div>Get started and create your first document set now!</div>
                    <div>
                        <Tooltip title="Upload">
                            <Button
                                onClick={toggleDrawer('right', true)}
                                label="upload"
                                color="info"
                                sx={{fontSize:'72px'}}>
                                <UploadFileIcon fontSize="inherit" />
                            </Button>
                        </Tooltip>
                    </div>
                </Container>
            </Container>
        );

        

    if (isLoading) return null;
    return (
        <React.Fragment>
            <List {...props}  filters={Filters} 
                    actions={<ListActions/>}
                    empty={<Empty />} >
                <Datagrid>
                    <TextField source="docsetname" label="Document Set" />
                    <ContentFilters label="Content Filters" />
                    <DocumentCount  label="Document Count" />
                    <TextField source="status" label="Status" />
                </Datagrid>
            </List>
           
            <Drawer
                anchor="right"
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
            >
                <CreateDocumentSet {...props} />
            </Drawer>
           
        </React.Fragment>
    )
}
