import { objectLessAttributes } from '@aws-amplify/core';
import { API, Amplify} from 'aws-amplify';

import { MultipartFileUpload, FileUpload, GetDocuments, GetDocumentSets, CreateDocumentSet, GetDocumentUrl, DeleteDocuments,DeleteDocumentSet} from './documents.js';
import { TemplateUpload, GetTemplates, DeleteTemplates } from './templates.js';
import { AnalyzeDocument,CreateBatchAnalysis,GetBatchAnalyses,DeleteBatchAnalysis} from './analysis.js';

import { GetFramework } from './frameworks.js';

import { HttpError } from 'react-admin';

let apiName = 'default';

let lastobservedkey;
let lastsort;
let isampconfigcomplete = false;

let delay = (time)=>{
	return new Promise(resolve => setTimeout(resolve, time));
}

let sort = (data,sort)=>{
	return data.sort((s1,s2)=> { 
		return (s1[sort.field]>s2[sort.field])
			?((sort.order=='DESC')?1:-1)
			:((sort.order=='DESC')?-1:1)
	})



}



export default {
    getList:    async( resource, params ) => {
		
		let listdata = {"data":[],"total":0}
        switch(resource.toLowerCase()){
            case "assessments":
                listdata.data.push({"id":"1","title":"my first assessment"});
				return new Promise(async (resolve,reject)=>{
					listdata.total = listdata.data.length;
					resolve(listdata);
				});
            break;
			case "documents":
				
				let documents = await GetDocuments(params);
				documents.data = (params.sort)?sort(documents.data,params.sort):documents.data;
				if(params.filter && params.filter.q){
					documents.data = documents.data.filter((f)=>{ return (f.filename.indexOf(params.filter.q)>=0)?true:false;});
					documents.total = documents.data.length;
				}
				return documents
			break;
			case "documentset":
				let documentsets = await GetDocumentSets();
				documentsets.data = (params.sort)?sort(documentsets.data,params.sort):documentsets.data;
				if(params.filter && params.filter.q){
					documentsets.data = documentsets.data.filter((f)=>{ return (f.docsetname.indexOf(params.filter.q)>=0)?true:false;});
					documentsets.total = documentsets.data.length;
				}
				if(params.filter && params.filter.status){
					documentsets.data = documentsets.data.filter((f)=>{ return (f.status==params.filter.status)?true:false;});
					documentsets.total = documentsets.data.length;
				}
				return documentsets;
			break;
			case "framework":
				let framework = await GetFramework((params.filter)?params.filter.framework:undefined);
				return framework;
			break;
			case "analysistemplates":
				let templates = await GetTemplates(params);
				templates.data = (params.sort)?sort(templates.data,params.sort):templates.data;
				if(params.filter && params.filter.q){
					templates.data = templates.data.filter((f)=>{ return (f.filename.indexOf(params.filter.q)>=0)?true:false;});
					templates.total = templates.data.length;
				}
				return templates;
			break;
			case "batchanalysis":
				let batches = await GetBatchAnalyses(params);
				batches.data = (params.sort)?sort(batches.data,params.sort):batches.data;
				if(params.filter && params.filter.q){
					batches.data = batches.data.filter((f)=>{ return (f.analysisname.indexOf(params.filter.q)>=0)?true:false;});
					batches.total = batches.data.length;
				}
				return batches;
			break;
			default:
				console.log(`requesting undefined resource ${resource}`);
				return listdata;
			break;

        }
        
		
    },
    getOne:     async(resource, params) => {
		
        return new Promise(async (resolve,reject)=>{
            
            resolve();
        });


	},
    getMany:    async(resource, params) => {

		let listdata = {"data":[]}
        switch(resource.toLowerCase()){
            case "documents":
				let documents = await GetDocuments(params);
				documents.data = (params.sort)?sort(documents.data,params.sort):documents.data;
				if(params.filter && params.filter.q){
					documents.data = documents.data.filter((f)=>{ return (f.filename.indexOf(params.filter.q)>=0)?true:false;});
					documents.total = documents.data.length;
				}

				return documents
			break;
			case "documentset":
				let documentsets = await GetDocumentSets();
				documentsets.data = (params.sort)?sort(documentsets.data,params.sort):documentsets.data;
				if(params.filter && params.filter.q){
					documentsets.data = documentsets.data.filter((f)=>{ return (f.docsetname.indexOf(params.filter.q)>=0)?true:false;});
					documentsets.total = documentsets.data.length;
				}
				return documentsets;
			break;
			case "framework":
				let framework = await GetFramework((params.filter)?params.filter.framework:undefined);
				return framework;
			break;
			case "analysistemplates":
				let templates = await GetTemplates(params);
				templates.data = (params.sort)?sort(templates.data,params.sort):templates.data;
				if(params.filter && params.filter.q){
					templates.data = templates.data.filter((f)=>{ return (f.filename.indexOf(params.filter.q)>=0)?true:false;});
					templates.total = templates.data.length;
				}
				return templates;
			break;
			case "batchanalysis":
				let batches = await GetBatchAnalyses(params);
				batches.data = (params.sort)?sort(batches.data,params.sort):batches.data;
				if(params.filter && params.filter.q){
					batches.data = batches.data.filter((f)=>{ return (f.analysisname.indexOf(params.filter.q)>=0)?true:false;});
					batches.total = batches.data.length;
				}
				return batches;
			break;
        }

		
	},
    getManyReference: (resource, params) => {
		return new Promise((resolve,reject)=>{
			resolve();
		});
	},
    create:     async(resource, params) => {
		
		console.log(`called create for resource ${resource}`);
		return new Promise(async(resolve,reject)=>{
			try{
			let listdata = {"data":[]}
			switch(resource.toLowerCase()){
				case "runanalysis":
						if(params.data.dataPointSource!='FREEFORM')	
						{
							let framework = await GetFramework(params.data.dataPointSource);
							params.data.question = framework.data.filter((f)=>f.id==params.data.id)[0].datapoint
						}
						resolve(await AnalyzeDocument(params));
					break;
				case "docset":
					resolve(await CreateDocumentSet(params));
					//resolve({"data":{id: params.data.docsetname.substring(0,3), name:params.data.docsetname}});
				break;
				case "batchanalysis":
						console.log(params);
						resolve(await CreateBatchAnalysis(params));
				break;
				default:
					console.error(`unkown create operation for resource ${resource.toLowerCase()}`);
				break;
			}
			}catch(err){
				console.error(`error in create calling ${resource}`);
				reject(err);
			}
		});
	},
    update:     async(resource, params) => {
		
		return new Promise(async(resolve,reject)=>{
            
			try{
				switch(resource.toLowerCase()){
					case "fileupload":
						//resolve(await FileUpload(params));
						resolve(await MultipartFileUpload(params));
					break;
					case "templateupload":
						resolve(await TemplateUpload(params));
					break;
					
				}
			}catch(err){
				reject(err);
			}
			

			
		});

	},
    updateMany: (resource, params) => {
		
		return new Promise((resolve,reject)=>{
			resolve();
		});
	},
    delete:     (resource, params) => {
		
		return new Promise((resolve,reject)=>{
			resolve();
		});
	},
    deleteMany: (resource, params) => {
		
		return new Promise(async(resolve,reject)=>{
            
			let result = {};
			try{
				switch(resource.toLowerCase()){
					case "documents":
						resolve(await DeleteDocuments(params));
					break;
					case "documentset":
						//resolve(await DeleteDocumentSet(params));
						resolve(await DeleteDocumentSet(params));
					break;
					case "analysistemplates":
						resolve(await DeleteTemplates(params));
					break;
					case "batchanalysis":
						resolve(await DeleteBatchAnalysis(params));
					break;
					default:
						reject(new HttpError(
							`requesting delete of undefined resource [${resource}]`,
							 500,
							 {}
						));
		
					break;
				}
			}catch(err){
				
				//reject(err);
				reject(new HttpError(
					(err.response.data)?(err.response.data.message || err.response.data.statusText):'Error deleting resource',
                     (err.response.data)?err.response.data.status:500,
					 err.response.data
				));
			}
			

			
		});
	},
}