import * as React from "react";
import { useState } from 'react';
import { required, SimpleForm, FileInput, FileField, TextInput, useRefresh} from 'react-admin';


import LinearProgress from '@mui/material/LinearProgress';

import { TemplateUploadToolbar } from '../components/templateuploadtoolbar';
import { DocumentSetCombo } from '../components/createdocumentset';

export const TemplateUpload = (props) => {
    const [validinputs,setValidInputs]=useState(false);
    const [isuploading,setIsUploading]=useState(false);
    const [nameset,setnameset]=useState(false);
    const [filesselected,setfilesselected]=useState(false);
    const [sheetnameset,setsheetnameset]=useState(false);
    const [colomnheaderset,setcolumnheaderset]=useState(false);

    const refresh = useRefresh();
  
    const formvalidator=(formvalues)=>{
        
        const errors = {};
        if (!formvalues.docsetid) {
            errors.docsetid = 'Select a template to upload.';
        }
        
        if(formvalues.attachments && formvalues.attachments.length==0){
            errors.attachments = "Select a file or file to upload."
        }

        if(formvalues.attachments && formvalues.attachments.length==0 && formvalues.attachments.length<=10){
            errors.attachments = "You may only upload up to 10 documents at once.  Please adjust your selection."
        }

        
        setValidInputs((Object.keys(errors).length==0));

        return {errors};
    }

    const callback_handler=(action,params)=>{
        
        switch(action.toLowerCase()){
            case 'onuploadstart':
                setIsUploading(true);
            break;
            case 'onuploadcomplete':
                setIsUploading(false);
                refresh();
            break;
            case 'onuploaderror':
                setIsUploading(false);
            break;
        }
    }

    const checknameset=(val)=>{
        setnameset((val.target.value.length>=3));
    }
    const checkfiles=(vals)=>{
        if(vals){
            setfilesselected(true);
        }
    }
    const checksheetnameset=(val)=>{
        setsheetnameset((val.target.value.length>=3));
    }

    const checkcolumnheaderset=(val)=>{
        setcolumnheaderset((val.target.value.length>=3));
    }

    return (
    
        <div style={{minWidth:'600px'}}>
        <SimpleForm toolbar={<TemplateUploadToolbar callback_events={callback_handler} formvalidated={(filesselected&&nameset&&sheetnameset&&colomnheaderset).toString()} />} validate={formvalidator}>
            <div className="flex-container">
                <TextInput source="templatename" fullWidth label="Template Name" id="txttemplatename" onChange={checknameset} />
            </div>
            <div className="flex-container">
                <TextInput source="sheetname" fullWidth label="Case Sensitive Worksheet Name Containing Analysis Points" id="txtsheetename" onChange={checksheetnameset} />
            </div>
            <div className="flex-container">
                <TextInput source="columnheadername" fullWidth label="Case Sensitive Column Header Name Containing Analysis Points" id="txtcolumnheader" onChange={checkcolumnheaderset} />
            </div>
            <div className="flex-container">
            <FileInput source="attachments" 
                        maxSize={4194304} 
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                        validate={required()}
                        placeholder="Click to select an Excel file for upload.  Files must be less than 4MB in size."
                        onChange={checkfiles}>
                <FileField source="src" title="title" target="_blank"  />
            </FileInput>
            </div>
           
        </SimpleForm>
            <div style={{width:'100%'}} hidden={!(isuploading)}>
                <LinearProgress>upload files</LinearProgress>
            </div>
        </div>
    
    );
        
    
}


