import { API, Amplify} from 'aws-amplify';
//import fetch, { Headers } from 'node-fetch';
import axios from 'axios';

const apiName = 'default';


const TemplateUpload = async(params)=>{
    
    
    return new Promise(async (resolve,reject)=>{
        let a = 0;
        let errors = [];
        
        

            
        let rparam = {"data":{"attachments":[]}};
        for(let ap of Object.keys(params.data))
        {
            if(ap!='attachments')
            {
                rparam[ap]=params.data[ap];
            }
        }
        
        params.data.attachments.data = await convertFileToBase64(params.data.attachments);
        rparam.data.attachments.push(params.data.attachments);

        let restconf = { headers:{"Content-Type":"application/json"}, body:rparam };
        try{
            let res = await API.post(apiName, '/app/uploadtemplate', restconf)
        }catch(err){
            console.error(err);
            errors.push(err);
        }

        a++;
    
        
        if(errors.length>0){
            reject(errors);
        }else{
            resolve({ data : {"id":1}});
        }
    });

}

const GetTemplates = async(params)=>{

    let qstring='?'
    if(params){
        for(let p of Object.keys(params)){
                switch(p){
                    case "pagination":
                        qstring += paramsToQueryString(params[p]);
                    break;
                    case "sort":
                        qstring += paramsToQueryString(params[p]);
                    break;
                    case "filter":
                        qstring += paramsToQueryString(params[p]);
                    break;
                }
        }
    }
    qstring = (qstring.length>1)?qstring.substring(0,qstring.length-1):'';
    return API.get(apiName, `/app/templates${qstring}`);

}

const DeleteTemplates = async(params)=>{
    let restconf = { headers:{"Content-Type":"application/json"}, body:params };
    return API.post(apiName, `/app/templates/delete`, restconf);
}

const GetTemplateObj = async(templateid)=>{
    let path = `/app/template/obj/${templateid}`
    console.log(`request to ${path}`)
    return await API.get(apiName, path);
}
const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => resolve({
        fileName: file.title,
        base64: reader.result
    });
    reader.onerror = reject;
});

const paramsToQueryString = (params) =>{
    let q='';
    for(let p of Object.keys(params)){
        if(params[p]!=undefined){
            q+=`${p}=${params[p]}&`
        }
    }
    return q
}



export { TemplateUpload, GetTemplates, DeleteTemplates, GetTemplateObj}