import * as React from "react";
import { useState } from 'react';
import { required, Button, SimpleForm, FileInput, FileField, TextInput, useRefresh} from 'react-admin';

import { useForm,Controller, useFormContext  } from 'react-hook-form';
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack'

import { DocumentSetCombo, CreateDocumentSetToolbar } from '../components/createdocumentset';

export const CreateDocumentSet = (props) => {
    const [validinputs,setValidInputs]=useState(false);
    const [isuploading,setIsUploading]=useState(false);
    const [docsetnameselected,setdocsetnameselected]=useState(false);
    const [filesselected,setfilesselected]=useState(false);
    const [filters,setFilters]=useState([]);
    
    

    const refresh = useRefresh();
    const formvalidator=(formvalues)=>{
        
        const errors = {};
        if (!formvalues.docsetname) {
            errors.docsetname = 'Specify a name for your Document Set.';
        }
        
       
        
        setValidInputs((Object.keys(errors).length==0));

        return {errors};
    }

    const callback_handler=(action,params)=>{
        
        switch(action.toLowerCase()){
            case 'onuploadstart':
                setIsUploading(true);
            break;
            case 'onuploadcomplete':
                setIsUploading(false);
                refresh();
            break;
        }
    }

    const checkdocsetname=(val)=>{
        setdocsetnameselected((val!=null));
    }
    const checkfiles=(vals)=>{
        setfilesselected((vals.length>0));
    }

    const Filter=(props)=>{
        const {caption} = props;
        const {filters, setFilters, filterComponents, setFilterComponents} = props.pstate;
        const { control,watch, unregister} = useForm({mode: "all",shouldUnregister:true});
        const { setValue } = useFormContext();

        const addFilter=(event)=>{

            setFilters([...filters, event.currentTarget.getAttribute('filterkey')]);
            let id = event.currentTarget.getAttribute('filterindex');
            
        }

        const removeFilter=(event)=>{
            
            let tfilters = [...filters];
            let id = event.currentTarget.getAttribute('filterindex');
            setValue(`txtfilter${id}`,'');
            setValue(`txtreplacement${id}`,'');
            tfilters.splice(tfilters.indexOf(event.currentTarget.getAttribute('filterindex')),1)
            setFilters(tfilters);
            
            
            
        }
        
        let filterKey = genKey();
        return (
            <div style={{display:'block'}}>
            <Stack direction="row" spacing={2} fullWidth>
                <FormControl margin="normal" fullWidth>
                    <Controller 
                        defaultValue="" 
                        control={control}
                        name={'txtfilter' + props.index}
                        render={({ field })=>(
                            <TextInput {...field} source={'txtfilter' + props.index} fullWidth label="Match Text" />
                        )}>
                    
                    </Controller>
                </FormControl>
                <FormControl margin="normal" fullWidth>
                    <Controller 
                        defaultValue="" 
                        control={control}
                        name={'txtreplacement' + props.index}
                        render={({ field })=>(
                            <TextInput {...field} source={'txtreplacement' + props.index} fullWidth label="Replacement Value" />
                        )}>
                    </Controller>
                </FormControl>
                <Button id={'btnfilterremove' + props.index} filterkey={filterKey}  filterindex={props.index} onClick={removeFilter} endIcon={<DeleteIcon />} />
            </Stack>
            </div>
        )
    }
    const FilterContainer=(props)=>{
        
        return (
            <>
                <Button id='btnfilteraddroot' onClick={()=>setFilters([...filters,genKey()])}><>Add Content Filter</></Button>
                {filters.map(m => <Filter key={m} caption={`Click ${m}`} pstate={{filters, setFilters}} index={m} />)}
            </>
        )


        
    }

    const genKey = ()=>{
        let key = (Math.random() + 1).toString(36).substring(2);
        console.log(key);
        return key;
    }

    return (
    
        <div style={{minWidth:'600px'}}>
        <SimpleForm toolbar={<CreateDocumentSetToolbar callback_events={callback_handler} formvalidated={(docsetnameselected).toString()} />} validate={formvalidator}>
            <div className="flex-container">
                <TextInput
                    source="docsetname"
                    label="Document Set Name"
                    //allowcreate="true"
                    //suggestionLimit={10}
                    validate={required()}
                    className="flex-container"
                    onChange={checkdocsetname}
                />
            </div>
            <div>
                <FilterContainer />
            </div>
           
        </SimpleForm>
            <div style={{width:'100%'}} hidden={!(isuploading)}>
                <LinearProgress>create Document Set</LinearProgress>
            </div>
        </div>
    
    );
        
    
}


