import { React,useEffect,useState, useMemo } from 'react';

import {
    AutocompleteInput,
    AutocompleteArrayInput,
    ReferenceInput,
    useCreate,
    SaveButton,
    Toolbar,
    useNotify,
    useUpdate,    
    useGetList,
    useCreateSuggestionContext,
    required
} from 'react-admin';

import { useForm, useFormContext } from "react-hook-form";

import { GetDocumentSets } from '../dataproviders/documents'


const DocumentsCombo = (props) =>{
    const [create] = useCreate();
    const [ddldocdata, setDdlDocData]= useState();
    const { register } = useForm();
    const [selecteddocset,setSelectedDocSet]=useState(props.selecteddocset);
    const [selectmultiple, setSelectMultiple]=useState(props.selectmultiple||true);

    useEffect(()=>{
         /*if(!ddldocdata){
             let docset = GetDocuments()
                .then((data)=>{
                    if(props.selectedDocSet){
                        console.log(`filter documents by selected docsetid ${props.selectedDocSet}`)
                        data.data = data.data.filter((f)=>f.docsetid==props.selectedDocSet)
                        console.log(data.data);
                        setDdlDocData(data.data);    
                    }
                })
                .catch((err)=>{
                    console.log('error calling GetDocuments()');
                    console.log(err);
                })
        }*/
    },[props.selecteddocset]);

    return (
        
            (selectmultiple)?
            <ReferenceInput label="Documents" source="docid" reference="documents" optionText="filename" optionValue="docid" suggestionLimit={props.suggestionLimit || 1000} filter={{docsetid:props.selecteddocset,status:props.status}}>        
                <AutocompleteArrayInput
                {...props} 
                optionText='filename'
                label="Document"
                value="-1"
                />
                </ReferenceInput>
            :<ReferenceInput label="Documents" source="docid" reference="documents" optionText="filename" optionValue="docid" suggestionLimit={props.suggestionLimit || 1000} filter={{docsetid:props.selecteddocset,status:props.status}}>
                <AutocompleteInput
                {...props} 
                optionText='filename'
                label="Document"
                value="-1"
                />
            </ReferenceInput>
    )
}

const DocumentSetCombo = (props) =>{
    //create={((props.allowCreate==true)?<CreateDocumentSet />:null)} 
    const [create] = useCreate();
    const [ddldata, setDdlData] = useState();
    const { register } = useForm();

    const onCreate = (docsetname) => {
        return new Promise((resolve,reject)=>{
            create(
                'docset',
                { data: { docsetname: docsetname } },
                {
                    onSuccess: (data) => {
                        let currentset = ddldata;
                        setDdlData(currentset.concat(data));
                        resolve(data);
                    },
                }
            );
        })
      
    }
    
        useEffect(()=>{
            if(!ddldata){
             let docset = GetDocumentSets()
                .then((data)=>{
                    setDdlData(data.data);
                })
                .catch((err)=>{
                    console.log('error calling GetDocumentSets()');
                    console.log(err);
                })
            }
        },[]);
    

    
    return (

        <ReferenceInput label="Document Set" source="docsetid" reference="documentset" optionText="docsetname" optionValue="id" suggestionLimit={props.suggestionLimit || 1000} filter={{'status':props.status}}>
                <AutocompleteInput
                {...props} 
                source={props.source || 'docsetid'}
                onCreate={ (props.allowcreate=='true')?onCreate:undefined }
                optionText='docsetname'
                optionValue='id'
                label="Document Set"
                className={props.className}
                />        
        </ReferenceInput>
    )
}

const CreateDocumentSetToolbar = (props) => {
    const [ create ] = useCreate();
    const { getValues, reset } = useFormContext();
    const notify = useNotify();
    const [issaving,setSavingState]=useState(false);
    
        const handleClick = e => {
            e.preventDefault(); // necessary to prevent default SaveButton submit logic
            const { ...data } = getValues();
            setSavingState(true);
            if(props.callback_events){
                props.callback_events('onuploadstart')
            }
            
            let filters = [];
            for(let k of Object.keys(data)){
                let match = k.match(/^(txtfilter|txtreplacement)(.+)/);
                if(match){
                    let filterkey = match[2];
                    let filterobj = filters.filter(f=>f.key==filterkey)[0] || {};
                    if(filters.indexOf(filterobj)>=0)
                    {
                        filters.splice(filters.indexOf(filterobj),1);
                    }

                    filterobj.key=filterkey;
                    filterobj.filter=(match[1].match(/filter$/))?data[k]:filterobj.filter;
                    filterobj.replace=(match[1].match(/replacement$/))?data[k]:filterobj.replace;
                    if(filterobj.filter!=''||filterobj.replace!='')
                        filters.push(filterobj);

                    delete data[k];
                }
            }
            data.contentfilters = filters;
            console.log(data);
            create(
                'docset',
                { data },
                { 
                    onSuccess: () => { 
                        setSavingState(false);
                        notify('Document Set created successfully.'); 
                        if(props.callback_events){
                            props.callback_events('onuploadcomplete')
                        }
                        reset();
                    }
                    ,onError: () => { 
                        setSavingState(false);
                        notify('Error creating Document Set.'); 
                        if(props.callback_events){
                            props.callback_events('onuploadcomplete')
                        }
                        reset();
                    }
                }
            );
            props.callback_events('onuploadcomplete');
            
            setSavingState(false);
        };
    
        const PostSaveButton = props => {
            const notify = useNotify();
            const reset = useFormContext();
            
            return <SaveButton {...props} 
                        label="Create Document Set"
                        type="button"
                        variant="text"
                        disabled={!(props.formvalidated=='true') || issaving}
                    />;
        };
        
    
        return <Toolbar>
                    <PostSaveButton type="button" onClick={handleClick} formvalidated={props.formvalidated} />
               </Toolbar>;
};


export { DocumentSetCombo, DocumentsCombo, CreateDocumentSetToolbar }