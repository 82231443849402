import { API, Amplify} from 'aws-amplify';


const apiName = 'default';

const delay = (time)=>{
	return new Promise(resolve => setTimeout(resolve, time));
}

const AnalyzeDocument = async(params)=>{
    return new Promise(async (resolve,reject)=>{
       
       let jsonpost = params.data;
       let restconf = { headers:{"Content-Type":"application/json"}, body:jsonpost };
       //resolve(API.post(apiName,'/app/analyzedocument',restconf))
       resolve(API.post(apiName,'/app/analyzedocument_submit',restconf))

    });

}

const CreateBatchAnalysis = async(params)=>{
    return new Promise(async (resolve,reject)=>{
       
       let jsonpost = params.data;
       let restconf = { headers:{"Content-Type":"application/json"}, body:jsonpost };
       //resolve(API.post(apiName,'/app/analyzedocument',restconf))
       resolve(API.post(apiName,'/app/analyzebatch_submit',restconf))

    });
}

const GetBatchAnalyses = async(params)=>{
    return new Promise(async (resolve,reject)=>{
        let jsonpost = params.data;
        let restconf = { headers:{"Content-Type":"application/json"}};
        let qstring='?'
        if(params){
            for(let p of Object.keys(params)){
                    switch(p){
                        case "pagination":
                            qstring += paramsToQueryString(params[p]);
                        break;
                        case "sort":
                            qstring += paramsToQueryString(params[p]);
                        break;
                        case "filter":
                            qstring += paramsToQueryString(params[p]);
                        break;
                    }
            }
        }
        qstring = (qstring.length>1)?qstring.substring(0,qstring.length-1):'';
        resolve(API.get(apiName,`/app/batchanalyses${qstring}`));

     });
}

const GetBatchAnalysisResult = async(params)=>{

           return new Promise(async (resolve,reject)=>{
            let restconf = { headers:{"Content-Type":"application/json"}};
            let qstring='?'
            if(params){
                qstring+=paramsToQueryString(params);
                
            }
            qstring = (qstring.length>1)?qstring.substring(0,qstring.length-1):'';
            resolve(API.get(apiName,`/app/analysisresults${qstring}`));
    
         });

}

const DeleteBatchAnalysis = async(params)=>{
       
       let restconf = { headers:{"Content-Type":"application/json"}, body:params };
       return API.post(apiName,'/app/batchanalyses/delete',restconf);
    
}


const paramsToQueryString = (params) =>{
    let q='';
    for(let p of Object.keys(params)){
        if(params[p]!=undefined){
            q+=`${p}=${params[p]}&`
        }
    }
    return q
}

export { AnalyzeDocument,CreateBatchAnalysis,GetBatchAnalyses,GetBatchAnalysisResult, DeleteBatchAnalysis }

