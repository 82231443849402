import React from 'react';
import { Datagrid, TextField,  DateField, BooleanField, TextInput, useRecordContext,useListContext, useNotify } from 'react-admin';
import { 
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    List,
    
} from 'react-admin';

import Button from '@mui/material/Button';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DescriptionIcon from '@mui/icons-material/Description';
import ArticleIcon from '@mui/icons-material/Article';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';


import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Box, Container,  Typography } from '@mui/material';
import { Route } from 'react-router';

import { Link } from "react-router-dom";
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { TemplateUpload } from "./templateupload";
import { Drawer } from '@material-ui/core'

import { GetDocumentUrl, GetDocumentObj } from "../dataproviders/documents";
import { GetTemplateObj } from "../dataproviders/templates";
import {isMobile} from 'react-device-detect';

import axios from 'axios';


import '../css/documents.css'



const DocLink = (props,ref) => {
    const record = useRecordContext();

    return(
        <Link {...props} to={`/documents/view/${record.id}`} target={`_view_${record.id}`}>{`${record.filename}`}</Link>
    )
}


const Filters = [
    <TextInput label="Template" source="templatename" defaultValue="" />,
    <TextInput label="Sheet Name" source="sheetname" defaultValue="" />,
    <TextInput label="Column Header" source="columnheadername" defaultValue="" />,
]


const TemplateButton = (props,ref) => {
    const record = useRecordContext();
    const notify = useNotify();

    const download = async ()=>{
        
        
        let template = await GetTemplateObj(record.id);//.then(async (template)=>{

                
            let rsp = await axios.get(template.url, {responseType: 'blob'});
            const targeturl = URL.createObjectURL(rsp.data);

            const a = document.createElement('a');
            a.download = `${template.title}.xlsx`;
            a.href = targeturl;
            a.addEventListener('click', (e) => {
                setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
            });
            a.click();

            notify(`${a.download} is being saved to your Downloads folder.`, { type: 'success' });
    }



    return(
        <Button {...props} to={`/templates/view/${record.id}`} target={`_view_${record.id}`} onClick={download}>Download</Button>
    )
}

export const AnalysisTemplatesList = (props) => {
    
    const { data, isLoading } = useListContext();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

      const toggleDrawer =
      (anchor, open) =>
      (event) => {
        if (
          event.type === 'keydown' &&
          ((event).key === 'Tab' ||
            (event).key === 'Shift')
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

      const ListActions = () => (
            <TopToolbar>
                <FilterButton/>
                <Button
                    onClick={toggleDrawer('right', true)}
                    label="upload"
                >
                    <UploadFileIcon/>
                </Button>
                <ExportButton/>
            </TopToolbar>
        );

        const Empty = () => (
            <Container>
                 <div className="question_noout_panel">
                    <div className="question_noout_container">
                    
                        <div className="question_noout_child">
                            <ul className="infodisplay">
                                <li><DescriptionIcon color="info" sx={{fontSize:'72px'}} /></li>
                                <li className="heading">Assessment Templates</li>
                                <li>
                                    Assessment templates are Excel formatted files containing a collection of data points for analysis.
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                <Container className="getstarted">
                    <div>Create your first assessment template now!</div>
                    <div>
                        <Tooltip title="Upload">
                            <Button
                                onClick={toggleDrawer('right', true)}
                                label="upload"
                                color="info"
                                sx={{fontSize:'72px'}}>
                                <UploadFileIcon fontSize="inherit" />
                            </Button>
                        </Tooltip>
                    </div>
                </Container>
            </Container>
        );
    

    if (isLoading) return null;
    return (
        <React.Fragment>
            <List {...props}  filters={Filters} 
                    actions={<ListActions/>}
                    empty={<Empty />} >
                <Datagrid>
                    <TextField source="templatename" label="Template" />
                    <TextField source="sheetname" label="Sheet Name" />
                    <TextField source="columnheadername" label="Column Header" />
                    <TextField source="totalanalysispoints" label="Total Analysis Points" />
                    <TextField source="inuse" label="Used by Assessments" />
                    <DateField source="created_date" label="Uploaded" />
                    <TemplateButton label="Download" />
                    
                </Datagrid>
            </List>
           
            <Drawer
                anchor="right"
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
            >
                <TemplateUpload {...props} />
            </Drawer>
           
        </React.Fragment>
    )
}
