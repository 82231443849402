import { React,useEffect,useState, useMemo } from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    useCreate,
    useGetList,
    useCreateSuggestionContext,
    required
} from 'react-admin';
import { useForm } from "react-hook-form";


import {
    Box,
    BoxProps,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@mui/material';


import { GetTemplates } from '../dataproviders/templates'


const TemplatesCombo = (props) =>{
    const [create] = useCreate();
    const [ddltemplatedata, setDdlTemplateData]= useState();
    const { register } = useForm();
    const [selectedtemplate,setSelectedTemplate]=useState(props.selectedtemplate);


    useEffect(()=>{
         if(!ddltemplatedata){
             let templates = GetTemplates()
                .then((data)=>{
                    if(props.selectedtemplate){
                        data.data = data.data.filter((f)=>f.templateid==props.selectedtemplate)
                        console.log(data.data);
                        setDdlTemplateData(data.data);    
                    }
                })
                .catch((err)=>{
                    console.log('error calling GetDocuments()');
                    console.log(err);
                })
        }
    },[props.selectedtemplate]);

  
    
    

    return (
        <ReferenceInput label="Templates" source="templateid" reference="analysistemplates" optionText="templatename" optionValue="templateid" suggestionLimit={props.suggestionLimit || 1000} filter={{templateid:props.selectedtemplate}}>
            <AutocompleteInput
                {...props} 
                optionText='templatename'
                label={props.TextFieldProps.placeholder }
                value="-1"
                />
        </ReferenceInput>
    )
}



export { TemplatesCombo }