import logo from './logo.svg';
import './App.css';
//react and react-admin objects
import * as React from "react";


import {useEffect} from  "react";
import { Admin, Resource, ListGuesser, CustomRoutes, Title, defaultTheme} from 'react-admin';
import { createTheme } from '@mui/material'

//amplify and react-admin Auth provider
import { Amplify, Auth } from 'aws-amplify';

import { Authenticator, ThemeProvider} from '@aws-amplify/ui-react';

import { AuthenticatorComponents, AuthenticatorTheme } from './components/AuthenticatorComponents';


import awsconfig from './config/awsconfig';
import '@aws-amplify/ui-react/styles.css';
import authProvider from './authproviders/authProvider';

//UI icons from material in SVG
import EngineeringIcon from '@mui/icons-material/Engineering';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DescriptionIcon from '@mui/icons-material/Description';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import SourceIcon from '@mui/icons-material/Source';

//data providers
import dataprovider from './dataproviders/restprovider';

//object customization
import { FileUpload } from './pages/fileupload';

import MyLayout from './components/layout';

//import routes and pages
import { Route } from "react-router";
import { Usage } from "./pages/usage";
import { DocViewer } from './pages/docviewer';
import { DocumentsList } from './pages/documents';
import { DocumentSetList } from './pages/documentsets';
import { RealtimeAnalysisList } from './pages/analysis';
import { BatchAnalysisList } from './pages/batchanalysis';
import { AnalysisTemplatesList, AssessmentTemplatesList } from './pages/analysistemplates'
import { Preferences } from './pages/preferences';


import('buffer').then(({Buffer}) => {global.Buffer = Buffer;})

//configure amplify



Amplify.configure(awsconfig);




const ascendaitheme = createTheme({
    ...defaultTheme,
    palette: {
      mode: 'light',
      primary: {
        main: '#FCAF3B',
        light: '#E6E6E6',
        dark: '#AB641D',
        contrastText: "#2B2B2B"
      },
      secondary:{
        main:'#000',
        light:'#999999',
        dark:'#444444',
        contrastText:'#fcac3c'
      },
      info:{
        main:'#ff9606',
        light:'#fcaf3b',
        dark:'#00000',
        contrastText:'#fcac3c'
      }
    }
  , typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [ 'Helvetica','Oswald','Arial', 'Verdana', 'sans-serif'].join(','),
    }    
});



function App() {
  setInterval(function () { let coguser= Auth.currentSession();  },60000)
  return (
    
        <Authenticator hideSignUp="true" components={AuthenticatorComponents}>
          <Admin  authProvider={authProvider}  
                  dataProvider={dataprovider} 
                  layout={MyLayout}
                  title="AscendAI.io"
                  theme={ascendaitheme}
                  >
              <CustomRoutes>
                    <Route
                      key="usage"
                      path="/usage"
                      element= { <Usage />}
                    />
                    <Route
                      key="preferences"
                      path="/preferences/user"
                      options={{ label: 'User Settings' }} 
                      element= { <Preferences />}
                    />
                    <Route
                      key="documentviewer"
                      path="/documents/view/:docid"
                      resource="docviewer"
                      element= { <DocViewer />}
                    />
                  
                    
                    
              </CustomRoutes>
              <Resource name="documentset" options={{ label: 'Document Sets' }} list={DocumentSetList} icon={SourceIcon} />
              <Resource name="documents" options={{ label: 'Documents' }} list={DocumentsList} icon={DescriptionIcon} />
              <Resource name="analysistemplates" options={{ label: 'Templates' }}  list={AnalysisTemplatesList} icon={DocumentScannerIcon} />
              <Resource name="batchanalysis" options={{ label: 'Assessments' }}  list={BatchAnalysisList} icon={BatchPredictionIcon } />
              <Resource name="analysis" options={{ label: 'Realtime Analysis' }}  list={RealtimeAnalysisList} icon={QuestionAnswerIcon} />
              
              
              { /*<Resource name="uploads" options={{ label: 'Uploads' }} list={FileUpload} icon={UploadFileIcon}  />  */ }      
              { /*<Resource name="batchanalysis" options={{ label: 'Batch Analysis' }}  list={ListGuesser} icon={EngineeringIcon} />*/}
              
          </Admin>
        </Authenticator>
    
  );
}

export default App;
/*export default function App(){
  return (
    <AmplifyAuthenticator />
  )
}*/
/*export default withAuthenticator(App, {
    loginMechanisms: ['email']
  //, signUpAttributes: ['email','name','website']
    , hideSignUp:true
  } );
*/
/*

*/